import { PageProps, Script, graphql } from "gatsby";
import React from "react";
import RecentBlog from "./recent-blogs";

import LayOut from "@components/layout";
import SEO from "@components/shared/seo";
import BlocksContent from "@components/shared/sub/blockContent";
import Image from "@components/shared/sub/image";
import { Query } from "@graphql-types";
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import { Container, GiveMeSomeSpace } from "@util/standard";
import { Helmet } from "react-helmet";
import styled from "styled-components";

const ImageWrapper = styled.div`
  width: 100%;
  height: 400px;
  margin-bottom: 30px;
  border-radius: 15px;
  overflow: hidden;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 350px;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 200px;
  }
`;

interface BlogsTemplateProps extends PageProps<Query> {}

const BlogTemplate = ({ data }: BlogsTemplateProps) => {
  const {
    allSanityBlogs: {
      edges: [{ node: blog }],
    },
  } = data;

  const slug =
    blog.blogType === "pressRelease"
      ? `/press-news/${blog._rawSlug.current}`
      : `/blog/${blog._rawSlug.current}`;

  return (
    <LayOut>
      <SEO
        overwriteTitle={blog.title}
        overwriteDescription={blog.excerpt}
        seoData={blog.seo}
        slug={slug}
      />
      <Helmet>
        {blog.jsonLd && (
          <Script type="application/ld+json">{`${blog.jsonLd}`}</Script>
        )}
      </Helmet>

      <Container
        display="flex"
        margin="50px auto 40px auto"
        width="90%"
        isMobileColumn
        mobileWidth="90%"
      >
        <Container
          padding="0px 50px 0px 0px"
          mobilePadding="0px"
          width="66%"
          mobileWidth="100%"
        >
          {blog.introImage && (
            <ImageWrapper>
              {blog.introImage && <Image data={blog.introImage} />}
            </ImageWrapper>
          )}

          <h2 className="blog-title">{blog.title}</h2>
          <span>{blog.publishdate}</span>
          <GiveMeSomeSpace space={20} />
          <BlocksContent blocks={blog.content?._rawColumnContent} />
        </Container>
        <Container margin="40px 0px 0px 0px">
          <RecentBlog currentSlug={blog._rawSlug.current || ""} />
        </Container>
      </Container>
    </LayOut>
  );
};

export const query = graphql`
  query singleBlogQuery($slug: String) {
    allSanityBlogs(filter: { slug: { current: { eq: $slug } } }) {
      edges {
        node {
          ...sanityBlogs
          blogType
        }
      }
    }
  }
`;

export default BlogTemplate;
