import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container } from "@util/standard";
import BlogCard from "@components/pages-components/blog/blogCard";

const RecentBlog = ({ currentSlug }) => {
  const {
    allSanityBlogs: { edges: data },
  } = useStaticQuery(graphql`
    query recentBlogQuery {
      allSanityBlogs(sort: { fields: publishdate, order: DESC }, limit: 4) {
        edges {
          node {
            ...sanityBlogs
          }
        }
        totalCount
      }
    }
  `);

  return (
    <Container>
      <h3>Recent Posts</h3>
      <Container display="flex" flexDirection="column" gridGap="25px">
        {data
          .filter(({ node: blog }) => {
            if (!blog.slug) return true;
            if (blog.slug.current !== currentSlug) return true;
            return false;
          })
          .slice(0, 3)
          .map(({ node: blog }) => {
            return (
              <BlogCard
                key={blog._id}
                blogCard={blog}
                smallSize
                isPress={blog.blogType === "pressRelease"}
              />
            );
          })}
      </Container>
    </Container>
  );
};

export default RecentBlog;
